import React from 'react';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paul from '../img/team/paul-no_bg.png';
import ResponsiveDialogPaul from './team_modals/Modal_Paul';
import Leo from '../img/team/leo-no_bg.png';
import ResponsiveDialogLeo from './team_modals/Modal_Leo';
import Bob from '../img/team/bob-no_bg.png';
import ResponsiveDialogBob from './team_modals/Modal_Bob';
import Joe from '../img/team/joseph-no_bg.png';
import ResponsiveDialogJoe from './team_modals/Modal_Joe';

const useStyles = makeStyles((theme) => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbartitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[300]
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
  paper: {
    position: 'relative',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: 'none'
  },
  team: {
    padding: theme.spacing(0, 0, 2)
  }
}));

const partners = [
  {
    name: 'Paul Politte',
    subheader: 'Partner',
    img: `${Paul}`,
    description: 'Short and sweet byline.',
    fullDescription: `Paul is globally recognized as one of the top people in the world in his field. He specializes in ERP implementations and the operation and improvement of Sales and Operations Planning, Demand Management and Distribution Resource Planning processes. He has successfully assisted multiple clients in achieving their goal of the "Class A" level of Operational Excellence performance and has provided guidance to clients around the world in over 30 countries covering 6 continents.
    Paul was one of the contributing authors to the 3 rd. edition Class A MRP II checklist developed by Oliver Wight and Associates in 1988. The fundamentals in that checklist form the basis of company specific checklists that have been developed and used by many of the world’s premier corporations such as DuPont, Proctor and Gamble, GlaxoSmithKline, Rohm and Haas Chemical and Ciba-Geigy.
    Drawing on over 30 years of industry experience; Paul has demonstrated expertise in the areas of ERP/MRPII, supply chain management, sales, customer service, product management, purchasing, materials management, distribution and warehousing, financial management and information systems.
    Paul headed the materials management function for Ciba-Geigy Corporation and directed the company's first "Class A" MRP II implementation. The project was documented in CPI Purchasing as one of the most successful in the process industry.
    Paul’s clients include some of the world’s most recognized manufacturing and distribution companies in the chemical process, pharmaceutical and consumer goods industries. Some of his more prominent clients include Dow, Sanofi-Aventis, DuPont, GlaxoSmithKline, Formica, Zeneca, Ciba Geigy, Werner Lambert, Merck, Bristol-Myers Squibb, Apotex, Pharmascience, Gillette and Rohm & Haas Chemical.
    Paul holds BS Chemistry and MBA degrees from the University of Arkansas.`,
    buttonText: 'Read More',
    buttonVariant: 'outlined'
  },
  {
    name: 'Leo Quilty',
    subheader: 'Partner',
    img: `${Leo}`,
    description: 'Short and sweet byline.',
    fullDescription: `Leo is a 30 year plus expert specializing in the implementation and improvement of ERP systems and business processes. Leo’s expertise spans most ERP platforms and every Supply Chain related business process. Transparency, passion and unequivocal counsel from the boardroom to the shop floor is a hall mark of his consultancy style.
    Leo was with GlaxoSmithKline in a variety of Supply Chain and Operations roles for almost 20 years. One of those roles was a 15 month assignment to a GSK subsidiary in Cairo, Egypt in 1993/94 where he was Project Leader for the implementation of a full suite of ERP operating software while also functionally leading the Supply Chain organization. The project achieved Class A certified status in MRP II and DRP and was recognized as one of the fastest and most robust implementations of its kind.
    He was a project team member of the first GSK site globally to achieve Class A MRP 11 certification status; Glaxo Canada in 1991. He then led the project for a Class A certified Distribution Resource Planning implementation in 1992. As Production Business Unit Manager in 1995/96, he led the prototype launch of a vertically integrated production unit while also implementing best practices in self- managed work teams. Leo finished his career with GSK in 1997 in the United Kingdom as Program Director of Operational Excellence. In that role he provided on site consulting advice, policy development support, best practice sharing and education programs to GSK sites globally.
    Leo’s clients have seen dramatic and sustainable improvements in customer service, reductions in working capital, improved quality indices performance, improved productivity at all levels in the organization and overall improvements in quality of work life for all staff. Since 1997, his client support has taken him to 22 countries and across 5 continents. His more prominent clients include GlaxoSmithKline, Rohm and Haas Chemical, Aventis Pasteur, Biovail, TorPharm Pharmaceuticals, Pharmascience Inc. and Accucaps Industries.
    Leo was educated in Arts and Physical Education at Memorial University and has studied business management at both the University of Moncton and York University. `,
    buttonText: 'Read More',
    buttonVariant: 'outlined'
  }
];

const associates = [
  {
    name: 'Robert Petrich',
    subheader: 'Associate ',
    img: `${Bob}`,
    description: 'Short and sweet byline.',
    fullDescription: `Bob has been consulting on world-class business management processes since his retirement from Rohm and Haas Company in 2001. His experience at Rohm and Haas covered a wide variety of responsibilities; including R&D manager, site manager for the European Technical Service Laboratories in France, North America Business Manager for Plastics Additives, Philadelphia Plant Manager, Corporate Director of Supply Chain & Logistics Processes, and VP of Operations for Electronic Materials.
    As Corporate Director of Supply Chain and Logistics, Bob provided the leadership to a very highly regarded Rohm and Haas Operational Excellence MRP II project during the period 1997 - 2001. That Class A certified implementation spanned 10 global business units and 65 manufacturing plants and commercial offices worldwide. The project laid the foundation for a very successful global SAP implementation.
    He has consulted in the chemical, packaging container, pharmaceutical and materials industries, with extensive international experience. His work encompasses the full range of business and supply chain management processes, with a particular focus on Demand Planning and Product Line Management and their linkages to Supply Chain through the Executive Sales & Operations Planning Process.
    Bob earned Bachelors and Masters Degrees in Chemical Engineering from MIT and a Ph.D. in Polymer Science from the University of Akron.`,
    buttonText: 'Read More',
    buttonVariant: 'outlined'
  },
  {
    name: 'Joseph Gilbert',
    subheader: 'Associate ',
    img: `${Joe}`,
    description: 'Short and sweet byline.',
    fullDescription: `Joe has been consulting on ERP software implementations since his retirement from Rohm and Haas Company in 2004. During his career he held a variety of technical management positions in both R&D and Corporate IT. He also spent 9 years as head of the Supply Chain Center and was the internal consultant to the Chemical Specialties businesses for their MRP II Class A project. Joe completed his career as IT and Benefits Director for their highly successful $300 MM global SAP ERP implementation that finished on schedule and under budget.
    He has consulted in the bio-technology, pharmaceutical and chemical industries. He provided support to one of the world’s largest pharmaceutical companies in their development of a global end-to-end ERP master design. He developed content and led a global 3 day conference on the subject - Delivering ERP Benefits. He has lectured on ERP and Class A MRP II at a Michael Hammer re-engineering conference.
    Joe supports Politte, Quilty and Associates in helping organizations implement global, best practice ERP software tools and business processes.
    Joe has a BS in Chemistry from LaSalle University and MS and PhD degrees in Computational Chemistry from Drexel University.`,
    buttonText: 'Read More',
    buttonVariant: 'outlined'
  }
];

export default function Team() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar} />
      </AppBar>
      <Container
        maxWidth="sm"
        component="main"
        className={classes.heroContent}
        id="The_Team"
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          The Team
        </Typography>
      </Container>
      {/* Beginning of Team Member Cards - Partners */}
      <Container maxWidth="md" component="main">
        {/* Paul */}
        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="center"
          className={classes.team}
        >
          <Grid item key={partners[0].name} xs={12} md={6}>
            <Card className={classes.card}>
              <CardHeader
                title={partners[0].name}
                subheader={partners[0].subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <Grid container justifyContent="center" alignItems="center">
                <img src={partners[0].img} alt={partners[0].name} />
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <CardActions>
                  <Grid item>
                    <ResponsiveDialogPaul />
                  </Grid>
                </CardActions>
              </Grid>
            </Card>
          </Grid>
          {/* Leo */}
          <Grid item key={partners[1].name} xs={12} md={6}>
            <Card className={classes.card}>
              <CardHeader
                title={partners[1].name}
                subheader={partners[1].subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <Grid container justifyContent="center" alignItems="center">
                <img src={partners[1].img} alt={partners[1].name} />
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <CardActions>
                  <Grid item>
                    <ResponsiveDialogLeo />
                  </Grid>
                </CardActions>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      {/* Continuation of Team Member Cards - Associates */}
      <Container maxWidth="md" component="main">
        <Grid
          container
          spacing={5}
          alignItems="center"
          justifyContent="center"
          className={classes.team}
        >
          {/* Bob */}
          <Grid item key={associates[0].name} xs={12} md={6}>
            <Card className={classes.card}>
              <CardHeader
                title={associates[0].name}
                subheader={associates[0].subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <Grid container justifyContent="center" alignItems="center">
                <img src={associates[0].img} alt={associates[0].name} />
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <CardActions>
                  <Grid item>
                    <ResponsiveDialogBob />
                  </Grid>
                </CardActions>
              </Grid>
            </Card>
          </Grid>
          {/* Joe */}
          <Grid item key={associates[1].name} xs={12} md={6}>
            <Card className={classes.card}>
              <CardHeader
                title={associates[1].name}
                subheader={associates[1].subheader}
                titleTypographyProps={{ align: 'center' }}
                subheaderTypographyProps={{ align: 'center' }}
                className={classes.cardHeader}
              />
              <Grid container justifyContent="center" alignItems="center">
                <img src={associates[1].img} alt={associates[1].name} />
              </Grid>
              <Grid container alignItems="center" justifyContent="center">
                <CardActions>
                  <Grid item>
                    <ResponsiveDialogJoe />
                  </Grid>
                </CardActions>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md" component="main">
        <br />
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}
