import React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import AutoPlay1 from './AutoPlayCarousel1';
// import AutoPlay2 from './AutoPlayCarousel2';

const useStyles = makeStyles((theme) => ({
  about: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 4)
  },
  root: {
    flexGrow: 1,
    height: '95vh'
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(20)
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  scroll: {
    padding: theme.spacing(4, 0, 0),
    filter: 'grayscale(100%)'
  },
  heroContent: {
    padding: theme.spacing(8, 0, 2)
  }
}));

const aboutContent = [
  {
    label: '1',
    content:
      'We are an independent consulting firm with a combined 200 years of experience in the implementation and improvement of supply chain systems, business planning and control processes and continuous improvement methodologies. We work across all levels of the organization from Executive Sales and Operations Planning through to Shop Floor Control and Execution. '
  },
  {
    label: '2',
    content:
      'The scope of our involvement encompasses company-wide education, coaching to best practice standards, project management support, formal policy and procedure development, process metrics, data accuracy and certification to the Operational Excellence Class A MRP II standard.'
  },
  {
    label: '3',
    content:
      'Over the course of the last 30 years, the team has successfully guided 40+ clients to world class levels of supply chain performance, across 35+ countries, spanning 6 continents. These businesses have ranged in size from $100 mm single site organizations to global multi-billion dollar enterprises and span a broad range of industries; with a particular emphasis on pharmaceuticals, specialty chemicals, consumer goods and electronics. '
  },
  {
    label: '4',
    content:
      'All clients have seen dramatic and sustainable improvements in customer service, reductions in working capital, improved quality performance, improved productivity at all levels in the organization and significant improvements in employee development and quality of work life. Our clients include some of the world\'s most respected and recognized organizations.'
  },
  {
    label: '5',
    content:
      ''
  },
  {
    label: '6',
    content:
      ''
  },
  {
    label: '7',
    content:
      ''
  }
];

export default function About() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}></Toolbar>
      </AppBar>
      <Container maxWidth="md" component="main" className={classes.heroContent} id="About_Us">
        <Typography
          component="h2"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          About Us
        </Typography>
      </Container>
      <Container>
        <Grid
          container
          classes={classes.root}
          alignContent="center"
          justifyContent="space-around"
        >
          {aboutContent.map((about) => (
            <Grid key={about} item="true" xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                variant="h5"
                align="left"
                color="textSecondary"
                paragraph
              >
                {about.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Hidden smDown>

      <Container className={classes.scroll} width="95%">
        <AutoPlay1 />
        {/* <AutoPlay2 /> */}
      </Container>
      </Hidden>
    </React.Fragment>
  );
}
