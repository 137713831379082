import React from 'react';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Logo from '../img/logos/pqc_logo.png';
import Email from '../img/e_mail.png';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1, 1, 1)
  },
  align: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  alignCenter: {
    justifyContent: 'space-evenly'
  },
  logo: {
    maxWidth: '10em'
  },
  email: {
    maxWidth: '0.8em'
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}></Toolbar>
      </AppBar>
      <Container className={classes.footer}>
        <Grid container direction="row" noWrap="true" className={classes.align}>
          <Grid item xs={12} md={6} align="center" className={classes.footer}>
            <img src={Logo} alt="logo" className={classes.logo} />
          </Grid>

          <Grid item xs={12} md={6} align="center" className={classes.footer}>
            <Typography
              variant="h5"
              align="center"
              color="textPrimary"
              paragraph
            >
              Contact Us
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textPrimary"
              paragraph
            >
            Phone: 647-242-5821
            <br />
            Email: Leo
            <img src={Email} alt="@" className={classes.email} />
            QuiltyInternational.com
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
