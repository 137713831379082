import React, { Component } from 'react';
import Slider from 'react-slick';
import Accucaps from '../img/brands/accucaps-sm.jpg';
import Bristol from '../img/brands/bristol-myers-150px.jpg';
import British from '../img/brands/british-airways-150px.jpg';
import Ciba from '../img/brands/ciba2-150px.jpg';
import Dow from '../img/brands/dow-sm.jpg';
import Dupont from '../img/brands/dupont-150px.jpg';
import Gillette from '../img/brands/gillette-150px.jpg';
import Glaxo from '../img/brands/glaxosmithkline-150px.jpg';
import Merck from '../img/brands/merck-150px.jpg';
import Pharma from '../img/brands/pharmascience-150.jpg';
import Rohm from '../img/brands/rohmhaas-150px.jpg';
import Sanofi from '../img/brands/sanofipasteur-sm.jpg';
import Warner from '../img/brands/warnerlambert-150px.jpg';
import Zeneca from '../img/brands/zeneca-150px.jpg';

export default class AutoPlay1 extends Component {
  // eslint-disable-next-line class-methods-use-this
  render() {
    const settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 4500,
      autoplaySpeed: 4500,
      cssEase: 'linear',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            speed: 4500,
            autoplaySpeed: 4500,
            cssEase: 'linear'
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            speed: 3500,
            autoplaySpeed: 3500,
            cssEase: 'linear'
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            speed: 3500,
            autoplaySpeed: 3500,
            cssEase: 'linear'
          }
        }
      ]
    };

    return (
      <div width="95%">
        <Slider {...settings}>
          <div>
            <img src={Accucaps} alt="Accucaps Logo" />
          </div>
          <div>
            <img src={Bristol} alt="Bristol-Myers Squibb Logo" />
          </div>
          <div>
            <img src={British} alt="British Airways Logo" />
          </div>
          <div>
            <img src={Ciba} alt="Ciba Geigy Logo" />
          </div>
          <div>
            <img src={Dow} alt="Dow Logo" />
          </div>
          <div>
            <img src={Dupont} alt="Dupont Logo" />
          </div>
          <div>
            <img src={Gillette} alt="Gillette Logo" />
          </div>
          <div>
            <img src={Glaxo} alt="Glaxosmithkline Logo" />
          </div>
          <div>
            <img src={Merck} alt="Merck Logo" />
          </div>
          <div>
            <img src={Pharma} alt="Pharmascience Logo" />
          </div>
          <div>
            <img src={Rohm} alt="Rohm and Haas Logo" />
          </div>
          <div>
            <img src={Sanofi} alt="Sanofi Pasteur Logo" />
          </div>
          <div>
            <img src={Warner} alt="Warner Lambert Logo" />
          </div>
          <div>
            <img src={Zeneca} alt="Zeneca Logo" />
          </div>
        </Slider>
      </div>
    );
  }
}
