import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './theme';
import HideAppBar from './components/TopAppBar';
import Landing from './components/Landing';
import About from './components/About';
import AboutParallax from './components/About_Parallax';
import Team from './components/Team';
import TeamParallax from './components/Team_Parallax';
import Successes from './components/Successes';
import SuccessesParallax from './components/Successes_Parallax';
import Education from './components/Education';
import EducationParallax from './components/Education_Parallax';
import Footer from './components/Footer';

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
      <CssBaseline />
      <HideAppBar />
      <Landing />
      <About />
      <AboutParallax />
      <Team />
      <TeamParallax />
      <Successes />
      <SuccessesParallax />
      <Education />
      <EducationParallax />
      <Footer />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
