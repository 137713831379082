import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
// eslint-disable-next-line react/display-name
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

function ResponsiveDialogPaul(props) {
  // eslint-disable-next-line react/prop-types
  const { fullScreen } = props;
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Read More
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Paul Politte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Paul is globally recognized as one of the top people in the world
              in his field. He specializes in ERP implementations and the
              operation and improvement of Sales and Operations Planning, Demand
              Management and Distribution Resource Planning processes. He has
              successfully assisted multiple clients in achieving their goal of
              the "Class A" level of Operational Excellence performance and has
              provided guidance to clients around the world in over 30 countries
              covering 6 continents. Paul was one of the contributing authors to
              the 3 rd. edition Class A MRP II checklist developed by Oliver
              Wight and Associates in 1988. The fundamentals in that checklist
              form the basis of company specific checklists that have been
              developed and used by many of the world’s premier corporations
              such as DuPont, Proctor and Gamble, GlaxoSmithKline, Rohm and Haas
              Chemical and Ciba-Geigy. Drawing on over 30 years of industry
              experience; Paul has demonstrated expertise in the areas of
              ERP/MRPII, supply chain management, sales, customer service,
              product management, purchasing, materials management, distribution
              and warehousing, financial management and information systems.
              Paul headed the materials management function for Ciba-Geigy
              Corporation and directed the company's first "Class A" MRP II
              implementation. The project was documented in CPI Purchasing as
              the first successful MRP II implementation in the world within the
              chemical process industry. Paul’s clients include some of the
              world’s most recognized manufacturing and distribution companies
              in the chemical process, pharmaceutical and consumer goods
              industries. Some of his more prominent clients include Dow,
              Sanofi-Aventis, DuPont, GlaxoSmithKline, Formica, Zeneca, Ciba
              Geigy, Werner Lambert, Merck, Bristol-Myers Squibb, Apotex,
              Pharmascience, Gillette and Rohm & Haas Chemical. Paul holds BS
              Chemistry and MBA degrees from the University of Arkansas.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialogPaul.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialogPaul);
