import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
// eslint-disable-next-line react/display-name
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

function ResponsiveDialogJoe(props) {
  // eslint-disable-next-line react/prop-types
  const { fullScreen } = props;
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Read More
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Joseph Gilbert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Joe has been consulting on ERP software implementations since his
              retirement from Rohm and Haas Company in 2004. During his career
              he held a variety of technical management positions in both R&D
              and Corporate IT. He also spent 9 years as head of the Supply
              Chain Center and was the internal consultant to the Chemical
              Specialties businesses for their MRP II Class A project. Joe
              completed his career as IT and Benefits Director for their highly
              successful $300 MM global SAP ERP implementation that finished on
              schedule and under budget. He has consulted in the bio-technology,
              pharmaceutical and chemical industries. He provided support to one
              of the world’s largest pharmaceutical companies in their
              development of a global end-to-end ERP master design. He developed
              content and led a global 3 day conference on the subject -
              Delivering ERP Benefits. He has lectured on ERP and Class A MRP II
              at a Michael Hammer re-engineering conference. Joe supports
              Politte, Quilty and Associates in helping organizations implement
              global, best practice ERP software tools and business processes.
              Joe has a BS in Chemistry from LaSalle University and MS and PhD
              degrees in Computational Chemistry from Drexel University.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialogJoe.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialogJoe);
