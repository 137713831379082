import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
// import Grid from '@mui/material/Grid';
// import { Hidden, Typography } from '@mui/material';
// import MenuIcon from '@mui/icons-material/Menu';
// import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  // '@global': {
  //   body: {
  //     backgroundColor: theme.palette.common.white
  //   },
  // },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap'
  },
  toolbarTitle: {
    flexGrow: 1
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  buttonText: {
    color: '#fff',
    border: '1px solid #eee'
  }
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.node.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  window: PropTypes.func
};

export default function TopAppBar(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar>
          <Toolbar className={classes.appBar}>
            {/* <Grid container>
              <Hidden mdUp>
                <Grid item>
                  <MenuIcon />
                </Grid>
              </Hidden>
              <Hidden smDown>
                <Grid container className={classes.alignRight} spacing={4}>
                  <Grid item>
                    <Typography>
                      <Button
                        variant="outlined"
                        href="#About_Us"
                        className={classes.buttonText}
                      >
                        About Us
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Button
                        variant="outlined"
                        href="#The_Team"
                        className={classes.buttonText}
                      >
                        The Team
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Button
                        variant="outlined"
                        href="#Client_Success_Stories"
                        className={classes.buttonText}
                      >
                        Client Success Stories
                      </Button>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <Button
                        variant="outlined"
                        href="#Education"
                        className={classes.buttonText}
                      >
                        Education
                      </Button>
                    </Typography>
                  </Grid>
                </Grid>
              </Hidden>
            </Grid> */}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}
