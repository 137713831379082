import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Background from '../img/backgrounds/toronto_skyline.jpg';
import MobileBackground from '../img/backgrounds/toronto.jpg';

const useStyles = makeStyles((theme) => ({
  parallax: {
    height: '95vh',
    width: '100%',
    'background-attachment': 'fixed',
    'background-position': 'top',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    [theme.breakpoints.up('md')]: {
      'background-image': `url(${Background})`
    },
    [theme.breakpoints.down('md')]: {
      'background-image': `url(${MobileBackground})`
    }
  },
  mdUpLandingGrid: {
    paddingTop: '10vh'
  },
  mdDownLandingGrid: {
    paddingTop: '20vh'
  },
  smDownLandingGrid: {
    paddingTop: '30vh'
  },
  landingText: {
    [theme.breakpoints.up('md')]: {
      color: '#FFFF',
      'text-shadow': '#FFF 1px 0 10px'
    },
    [theme.breakpoints.down('md')]: {
      color: '#000',
      'text-shadow': '#000 1px 0 10px'
    }
  }
}));

export default function Landing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <div className={classes.parallax}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.mdUpLandingGrid}
        >
          <Hidden mdUp>
            <Grid item>
              <Typography
                component="h1"
                variant="h2"
                className={classes.landingText}
                gutterBottom
                align="center"
              >
                Politte, Quilty & Associates
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                className={classes.landingText}
              >
                Executive Advisory, Education and Implementation Services
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          className={classes.mdDownLandingGrid}
        >
          <Hidden mdDown>
            <Grid item>
              <Typography
                component="h1"
                variant="h2"
                className={classes.landingText}
                gutterBottom
                align="center"
              >
                Politte, Quilty & Associates
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                align="center"
                className={classes.landingText}
              >
                Executive Advisory, Education and Implementation Services
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </React.Fragment>
  );
}
