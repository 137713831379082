import React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import Accucaps from '../img/brands/accucaps-sm.jpg';
import Ciba from '../img/brands/ciba2-150px.jpg';
import Dow from '../img/brands/dow-sm.jpg';
import Glaxo from '../img/brands/glaxosmithkline-150px.jpg';
import Pharma from '../img/brands/pharmascience-150.jpg';
import Rohm from '../img/brands/rohmhaas-150px.jpg';
import Sanofi from '../img/brands/sanofipasteur-sm.jpg';

const useStyles = makeStyles((theme) => ({
  successes: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2, 4)
  },
  // root: {
  //   flexGrow: 1,
  //   height: '95vh'
  // },
  paper: {
    height: 140,
    width: 100
  },
  // control: {
  //   padding: theme.spacing(20)
  // },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  heroContent: {
    padding: theme.spacing(8, 0, 2)
  },
  client: {
    padding: theme.spacing(0, 0, 6)
  }
}));

const successContent = [
  {
    company: 'Ciba-Geigy',
    logo: Ciba,
    content1: 'Glens Falls NY - Pigments division of one of the world’s largest chemical companies. This project was recognized as the first successful MRP II Class A implementation in the chemical process industry in the world.',
    content2: 'Along with the typical achievement of over 95% Customer Service, raw material inventory was reduced by 65% for a savings of over $4 mm while overall inventory turns rose from an average of 3 to 4 to between 9 and 10. There were significant benefits achieved in the procurement area with planner/buyer activities. Pre-Class A, an average of 7 hours per day was spent on ordering and expediting. Post Class A, 5 hours a day were spent on cost reduction and value analysis activities along with mrp review and analysis.'
  },
  {
    company: 'Rohm and Haas Chemical',
    logo: Rohm,
    content1: 'In 1997 at project start, a $2 billion specialty chemical company with 10 global business units and over 100 plants and commercial offices worldwide. Sales of almost $9 billion when purchased by Dow in 2009. Class A certification was achieved across every business unit. It is notable that the Class A project was implemented across multiple, different ERP software platforms and after the Class A project was completed the company implemented a global SAP ERP project. That project was implemented on time and on budget in 2 years. It was at the time and since been regarded as one of the fastest and most robust global implementations of SAP ever achieved. Consensus opinion was that the project success was achieved primarily because the company was implementing on Class A capable business processes.',
    content2: 'Along with achieving 95% customer service performance across all divisions, the business improved overall global productivity by 40% while simultaneously experiencing 8% growth. No capital expansion required for 5 years post implementation, when historically a new reactor was built approximately every 2 years.'
  },
  {
    company: 'Dow AgroSciences',
    logo: Dow,
    content1: 'Global agricultural chemical division of Dow Chemical; one of the world’s largest and most recognizable chemical companies. The AgroSciences division decided to implement MRP II Class A after they had purchased the Class A operating Agricultural Chemicals division of Rohm and Haas and subsequently realized the benefits of Class A business processes. The AgroSciences division became the most profitable within the Dow group of companies. Dow eventually bought the total Rohm and Haas business in 2009 for $14 billion - over 6 times the market cap from when they began their Class A journey in 1997.',
    content2: ''
  },
  {
    company: 'GlaxoSmithKline',
    logo: Glaxo,
    content1: 'North Carolina manufacturing site of one of the world\'s largest pharmaceutical companies, with over $5 billion in sales and 2000 plus employees. Achieved Customer Service over 95% from a baseline at beginning of project of 42%. Reduced cost of poor quality by over 50%.',
    content2: ''
  },
  {
    company: 'Sanofi Aventis Pasteur - Toronto',
    logo: Sanofi,
    content1: 'Canadian vaccines division of one of the world’s largest pharmaceutical companies. Extremely complex implementation with manufacturing lead times months long and dependent on morbidity rates of test animals. Achieved Customer Service levels greater than 97% and consistent 95% performance in plant scheduling, inventory record accuracy, supplier on time delivery and master data accuracy greater than 98%.',
    content2: ''
  },
  {
    company: 'Glaxo Canada Inc.',
    logo: Glaxo,
    content1: 'Canadian division of one of the world\'s largest pharmaceutical companies. Achieved over 6 inventory turns from a baseline of 1.7. Achieved Customer Services levels of 99% and over a 3-year period rose from number 8 to number 1 in annual independent industry surveys of the best service providers in the country. With the implementation of a Class A certified DRP implementation following the MRP II implementation; reduced distribution cost to sales ratio from 2.3% to 1.4%.',
    content2: ''
  },
  {
    company: 'Pharmascience Inc.',
    logo: Pharma,
    content1: 'Montreal based and second largest Canadian generic pharmaceutical company. Achieved Customer Service levels of over 95% and greater than 95% performance across all data accuracy parameters and manufacturing schedule performance metrics. Had historically ranked in the bottom quartile of the annual Advantage Report Survey™ on overall supply chain performance in the industry. Certified Class A MRP II by Politte, Quilty and Associates in June 2012. In September 2012, achieved number one ranking in all service related categories across all industry segments including branded and generic firms.',
    content2: ''
  },
  {
    company: 'Accucaps Industries Ltd.',
    logo: Accucaps,
    content1: 'Canadian soft gelatin contract pharmaceutical manufacturer. Supplier of major brands to global clients such as Proctor and Gamble and GlaxoSmithKline. Achieved Customer Service performance of 96% from a baseline of 67%. Turned a 4 year average annual net income loss of about $4 mm to an annual net income profit of over $2 mm.',
    content2: ''
  }
];

export default function Successes() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}></Toolbar>
      </AppBar>
      <Container
        maxWidth="md"
        component="main"
        className={classes.heroContent}
        id="Client_Success_Stories"
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Client Success Stories
        </Typography>
      </Container>
      <Container className={classes.successes}>
        <Grid
          container
          direction="column"
          classes={classes.root}
          alignContent="center"
          justifyContent="space-around"
        >
          {successContent.map((success) => (
            <Grid
              key={success}
              item="true"
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.client}
            >
              <Typography
                variant="h3"
                align="center"
                color="textSecondary"
                paragraph
              >
                <img src={success.logo} alt="logo" />
              </Typography>
              <Typography
                variant="h5"
                align="left"
                color="textSecondary"
                paragraph
              >
                {success.content1}
              </Typography>
              <Typography
                variant="h5"
                align="left"
                color="textSecondary"
                paragraph
              >
                {success.content2}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
