import React from 'react';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles((theme) => ({
  education: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0, 2, 4)
  },
  root: {
    flexGrow: 1,
    height: '100vh'
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(20)
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  heroContent: {
    padding: theme.spacing(8, 0, 2)
  }
}));

const educationContent = [
  {
    label: '1',
    content:
    'We offer 3 classes; a 2-day Executive overview, a 3-day Management and Professional staff overview and a one-half day class for all other employees in the company.'
  },
  {
    label: '2',
    content:
    'The classes are not your typical slide presentations sessions. They are invariably highly interactive discussions between instructors and class participants. This occurs primarily as a result of the fact the classes would be conducted after the initial business assessment. Therefore the instructors have an in-depth perspective on the issues and opportunities within the business. The particular business issues within the agenda items are debated and consensus reached on definition of key process gaps and next steps to address.'
  },
  {
    label: '3',
    content:
    'All classes also have students participate in a proprietary business simulation exercise we call the Supply Chain Game. The simulation covers every aspect of a manufacturing and sales company; from forecasting of sales through to ordering of raw materials, warehousing, manufacturing and finally distribution to the customer.'
  },
  {
    label: '4',
    content:
    'There are 4 rounds and between rounds continuous improvement opportunities are implemented along with integrated MRP II planning and control processes such as Distribution Requirements Planning (DRP) for auto-replenishment of product to distribution centers and inter-plant transfers, Forecasting of product sales, Supplier Scheduling, Master Production Scheduling and Sales and Operations Planning (S&OP).'
  },
  {
    label: '5',
    content:
    'The simulation has two major objectives:'
  },
  {
    label: '6',
    content:
    '1. Provide participants with hands-on experience and insight into the implementation and operation of effective Supply Chain management processes'
  },
  {
    label: '7',
    content:
    '2. Illustrate the benefits of applying S&OP and MRP II processes within a Continuous Improvement methodology to greatly improve performance'
  },
  {
    label: '8',
    content:
    ''
  }
];

export default function Education() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar} />
      </AppBar>
      <Container
        maxWidth="md"
        component="main"
        className={classes.heroContent}
        id="Education"
      >
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Education
        </Typography>
      </Container>
      <Container className={classes.education}>
        <Grid
          container
          direction="column"
          classes={classes.root}
          alignContent="left"
          justifyContent="space-around"
        >
          {educationContent.map((education) => (
            <Grid
              key={education}
              item="true"
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Typography
                variant="h5"
                align="left"
                color="textSecondary"
                paragraph
              >
                {education.content}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}
