import React from 'react';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@mui/material/Hidden';
import Background from '../img/backgrounds/pyramids_night.jpg';

const useStyles = makeStyles(() => ({
  parallax: {
    'background-image': `url(${Background})`,
    height: '95vh',
    width: '100%',
    opacity: '.8',
    'background-attachment': 'fixed',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
    'background-size': 'cover'
  }
}));

export default function TeamParallax() {
  const classes = useStyles();

  return (
    <React.Fragment>
    <Hidden smDown>

      <CssBaseline />
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography
            variant="h6"
            color="inherit"
            noWrap="true"
            className={classes.toolbarTitle}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.parallax}></div>
    </Hidden>
    </React.Fragment>
  );
}
