import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
// eslint-disable-next-line react/display-name
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

function ResponsiveDialogLeo(props) {
  // eslint-disable-next-line react/prop-types
  const { fullScreen } = props;
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Read More
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Leo Quilty</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Leo is a 30 year plus expert specializing in the implementation
              and improvement of ERP systems and business processes. Leo’s
              expertise spans most ERP platforms and every Supply Chain related
              business process. Transparency, passion and unequivocal counsel
              from the boardroom to the shop floor is a hall mark of his
              consultancy style. Leo was with GlaxoSmithKline in a variety of
              Supply Chain and Operations roles for almost 20 years. One of
              those roles was a 15 month assignment to a GSK subsidiary in
              Cairo, Egypt in 1993/94 where he was Project Leader for the
              implementation of a full suite of ERP operating software while
              also functionally leading the Supply Chain organization. The
              project achieved Class A certified status in MRP II and DRP and
              was recognized as one of the fastest and most robust
              implementations of its kind. He was a project team member of the
              first GSK site globally to achieve Class A MRP 11 certification
              status; Glaxo Canada in 1991. He then led the project for a Class
              A certified Distribution Resource Planning implementation in 1992.
              As Production Business Unit Manager in 1995/96, he led the
              prototype launch of a vertically integrated production unit while
              also implementing best practices in self- managed work teams. Leo
              finished his career with GSK in 1997 in the United Kingdom as
              Program Director of Operational Excellence. In that role he
              provided on site consulting advice, policy development support,
              best practice sharing and education programs to GSK sites
              globally. Leo’s clients have seen dramatic and sustainable
              improvements in customer service, reductions in working capital,
              improved quality indices performance, improved productivity at all
              levels in the organization and overall improvements in quality of
              work life for all staff. Since 1997, his client support has taken
              him to 22 countries and across 5 continents. His more prominent
              clients include GlaxoSmithKline, Rohm and Haas Chemical, Aventis
              Pasteur, Biovail, TorPharm Pharmaceuticals, Pharmascience Inc. and
              Accucaps Industries. Leo was educated in Arts and Physical
              Education at Memorial University and has studied business
              management at both the University of Moncton and York University.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialogLeo.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialogLeo);
