import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

// FIXME checkout https://mui.com/components/use-media-query/#using-material-uis-breakpoint-helpers
// eslint-disable-next-line react/display-name
const withMobileDialog = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="lg" fullScreen={false} />;

function ResponsiveDialogBob(props) {
  // eslint-disable-next-line react/prop-types
  const { fullScreen } = props;
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Read More
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Robert Petrich</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography>
              Bob has been consulting on world-class business management
              processes since his retirement from Rohm and Haas Company in 2001.
              His experience at Rohm and Haas covered a wide variety of
              responsibilities; including R&D manager, site manager for the
              European Technical Service Laboratories in France, North America
              Business Manager for Plastics Additives, Philadelphia Plant
              Manager, Corporate Director of Supply Chain & Logistics Processes,
              and VP of Operations for Electronic Materials. As Corporate
              Director of Supply Chain and Logistics, Bob provided the
              leadership to a very highly regarded Rohm and Haas Operational
              Excellence MRP II project during the period 1997 - 2001. That
              Class A certified implementation spanned 10 global business units
              and over 100 manufacturing plants and commercial offices worldwide. The
              project laid the foundation for a very successful global SAP
              implementation. He has consulted in the chemical, packaging
              container, pharmaceutical and materials industries, with extensive
              international experience. His work encompasses the full range of
              business and supply chain management processes, with a particular
              focus on Demand Planning and Product Line Management and their
              linkages to Supply Chain through the Executive Sales & Operations
              Planning Process. Bob earned Bachelors and Masters Degrees in
              Chemical Engineering from MIT and a Ph.D. in Polymer Science from
              the University of Akron.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ResponsiveDialogBob.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog()(ResponsiveDialogBob);
